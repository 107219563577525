import type { I18n } from 'nordic/i18n';
import type { TranslationsDictionary } from './i18n.types';

export const resolveTranslations = (
  i18n: I18n,
): Omit<TranslationsDictionary, 'get'> => ({
  player: {
    audioAriaLabel: i18n.gettext('Audio'),
    audioSubtitleSelectorAriaLabel: i18n.gettext(
      'Selección de audio y subtítulo',
    ),
    availableAudiosTitle: i18n.gettext('Audio'),
    availableSubtitlesTitle: i18n.gettext('Subtítulos'),
    closeAriaLabel: i18n.gettext('Cerrar'),
    disableSubtitle: i18n.gettext('No'),
    enterFullscreenAriaLabel: i18n.gettext('Ingresar a pantalla completa'),
    exitFullscreenAriaLabel: i18n.gettext('Salir de pantalla completa'),
    forwardAriaLabel: i18n.gettext('Avanzar 10 segundos'),
    loading: i18n.gettext('Cargando'),
    muteAriaLabel: i18n.gettext('Clic para activar audio'),
    pauseAriaLabel: i18n.gettext('Pausar'),
    playAriaLabel: i18n.gettext('Iniciar'),
    playbackProgressAriaLabel: i18n.gettext('Control de reproducción'),
    rewindAriaLabel: i18n.gettext('Retroceder 10 segundos'),
    subtitlesAriaLabel: i18n.gettext('Subtítulos'),
    togglePlaybackAriaLabel: i18n.gettext('Alternar reproducción/pausa'),
    unmuteAriaLabel: i18n.gettext('Activar audio'),
    volumeAriaLabel: i18n.gettext('Volumen'),
    volumeAutoplayUnmuteLabel: i18n.gettext('Clic para activar audio'),
    restartLabel: i18n.gettext('Volver a empezar'),
    retryButtonLabel: i18n.gettext('Reintentar'),
    errorText: i18n.gettext('Lo sentimos, no pudimos cargar el contenido'),
  },
  modal: {
    defaultCloseButtonLabel: i18n.gettext('Cerrar'),
  },
  deviceNotAllowed: {
    imageTitle: i18n.gettext(
      'Intenta acceder a Mercado Play desde otro navegador.',
    ),
    title: i18n.gettext('Intenta acceder a Mercado Play desde otro navegador.'),
    subtitle: i18n.gettext('Estamos trabajando para mejorar la experiencia.'),
    buttonLabel: i18n.gettext('Volver a Mercado Libre'),
  },
  updateDevice: {
    imageTitle: i18n.gettext(
      'Actualiza tu navegador para acceder a Mercado Play.',
    ),
    title: i18n.gettext('Actualiza tu navegador para acceder a Mercado Play.'),
    buttonLabel: i18n.gettext('Volver a Mercado Libre'),
  },
  forbiddenErrorPage: {
    imageTitle: i18n.gettext('Usuario sin permisos'),
    title: i18n.gettext('No tienes permiso para acceder a esta sección'),
    subtitle: i18n.gettext(
      'Estamos trabajando para brindarte la mejor experiencia.',
    ),
    buttonLabel: i18n.gettext('Volver a Mercado Libre'),
  },
  middleEndNotAvailable: {
    title: i18n.gettext('Lo sentimos, no pudimos cargar esta sección.'),
    subtitle: i18n.gettext('Por favor, inténtalo nuevamente.'),
    buttonLabel: i18n.gettext('Reintentar'),
  },
  unknownErrorPage: {
    title: i18n.gettext('Lo sentimos, no pudimos cargar esta sección.'),
    subtitle: i18n.gettext('Por favor, inténtalo nuevamente.'),
    buttonLabel: i18n.gettext('Reintentar'),
  },
  downloadAppMobile: {
    metadataTitle: i18n.gettext('Mercado Play - Series y películas gratis'),
    metadataDescription: i18n.gettext(
      'Mercado Play es una sección de Mercado Libre en la que vas a encontrar contenidos gratis, de forma fácil, rápida y segura.',
    ),
    imageTitle: i18n.gettext(
      'Descarga la app de Mercado Libre para disfrutar series y películas gratis',
    ),
    title: i18n.gettext(
      'Descarga Mercado Libre para disfrutar series y películas gratis ',
    ),
    subtitle: i18n.gettext('En tu celular, computadora y TV.'),
    buttonLabel: i18n.gettext('Descargar Mercado Libre'),
  },
  sparkleSearchNative: {
    imageTitle: i18n.gettext('Series y películas gratis'),
    title: i18n.gettext('Series y películas gratis'),
    buttonLabel: i18n.gettext('Ir a Mercado Play'),
  },
  downloadAppTablet: {
    imageTitle: i18n.gettext(
      'Descarga la app de Mercado Libre para disfrutar series y películas gratis',
    ),
    title: i18n.gettext(
      'Descarga la app de Mercado Libre para disfrutar series y películas gratis',
    ),
    subtitle: i18n.gettext('También puedes acceder desde tu computadora.'),
    buttonLabel: i18n.gettext('Volver a Mercado Libre'),
  },
  siteNotAvailable: {
    imageTitle: i18n.gettext('Mercado Play no está disponible en tu ubicación'),
    title: i18n.gettext('Mercado Play no está disponible en tu ubicación'),
    buttonLabel: i18n.gettext('Volver a Mercado Libre'),
  },
  splashScreenText: {
    imageAlt: i18n.gettext(
      'Fondo con portadas de algunos de los títulos disponibles Mercado Play',
    ),
    logoAlt: i18n.gettext('Mercado Play, películas y series gratis'),
    title: i18n.gettext('Series y películas gratis ¡Ahora también en tu TV!'),
  },
});
